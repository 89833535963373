
import {Component, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import {ConfirmCustomizationApi, DeleteCartApi, UpdateNumberApi} from "@/network/modules";
import {DeleteCartReq, UpdateNumberReq} from "@/interface/res";

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["shoppingCartNum","shoppingCartList"])
  }
})
export default class ShoppingCartItem extends Vue {
  @Prop({ type: Object, default: ()=>{} }) readonly data : any;
  @Prop({ type: Boolean }) readonly dataChecked ?: boolean;
  @Provide() loading:boolean = false
  @Provide() dataNum:number = 0
  @Provide() totalPrice:number = 0
  @Provide() deleteLoading:boolean = false
  @Provide() deleteShow:boolean = false
  @Provide() checked:boolean = false
  @Watch('data',{immediate:true, deep:true})
  @Watch('dataChecked',{immediate:true, deep:true})
  private iDataChange():void {
    const _this:any = this;
    _this.dataNum = _this.data.cartNum
    _this.checked = _this.dataChecked
    _this.totalPrice = _this.$base.accMul(_this.data.cartPrice,_this.data.cartNum)
  }
  mounted(){
    const _this:any = this;

  }

  /**
   * @Author HS
   * @Date 2021/8/24 2:07 下午
   * @Description: 发起确认
   * @Params: null
   * @Return: null
  */
  async ConfirmCustomizationFn(){
    const _this:any = this;

    let params:DeleteCartReq = {
      cartId:_this.data.cartId,
    }
    const res = await ConfirmCustomizationApi(params)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    let arr = [_this.data.thumbnailUrl,_this.data.commodityName,_this.data.createTime,_this.data.cartId]
    let str = arr.join(',')
    _this.data.disposalStatus = 2
    _this.$store.commit('SET_SERVICESHOW',true)
    _this.$store.commit('SET_UNREAD',0);
    _this.$store.commit('SET_CUSTOMIZEINFO',str);

  }


  /**
   * @Author HS
   * @Date 2021/7/22 4:11 下午
   * @Description: 数量切换
   * @Params: { string ： str - true } [str: add-增加 minus-减少]
   * @Return: null
  */
  itemNumChange(str:string){
    const _this:any = this;
    if (_this.loading) return
    console.log(_this.data.commoditySeries)
    console.log(str)
    switch (str) {
      case 'add':
        switch (_this.data.commoditySeries) {
          case 0:
            let xjbyList = _this.shoppingCartList.filter((item:any) => item.commoditySeries == 0)
            let xjbyNum = 0;
            xjbyList.map((item:any) => {
              if(
                  item.commoditySeries != 2 && item.invalidStatus == 0 ||
                  item.commoditySeries == 2 && item.disposalStatus == 1 && item.invalidStatus == 0
              ){
                xjbyNum += parseInt(item.cartNum)
              }
            })
            if(xjbyNum >= _this.shoppingCartNum.xjbyMaxNum){
              _this.$message.error("丝秘花园，超出购买上限！");
              return;
            }
            break;
          case 1:
            let cxjjList = _this.shoppingCartList.filter((item:any) => item.commoditySeries == 1)
            let cxjjNum = 0;
            cxjjList.map((item:any) => {
              if(
                  item.commoditySeries != 2 && item.invalidStatus == 0 ||
                  item.commoditySeries == 2 && item.disposalStatus == 1 && item.invalidStatus == 0
              ){
                cxjjNum += parseInt(item.cartNum)
              }
            })
            if(cxjjNum >= _this.shoppingCartNum.cxjjMaxNum){
              _this.$message.error("储绣家居，超出购买上限！");
              return;
            }
            break;
          case 2:
            let gjdzList = _this.shoppingCartList.filter((item:any) => item.commoditySeries == 2)
            let gjdzNum = 0;
            gjdzList.map((item:any) => {
              // if(
              //     item.commoditySeries != 2 && item.invalidStatus == 0 ||
              //     item.commoditySeries == 2 && item.disposalStatus == 1 && item.invalidStatus == 0
              // ){
              //   gjdzNum += parseInt(item.cartNum)
              // }
               if(item.commoditySeries == 2 && item.invalidStatus == 0){
                gjdzNum += parseInt(item.cartNum)
              }
            })
            if(gjdzNum >= _this.shoppingCartNum.gjdzMaxNum){
              _this.$message.error("高级定制，超出购买上限！");
              return;
            }
            break;
        }
        _this.UpdateNumberFn(1)
        break;
      case 'minus':
        if(_this.dataNum <= 1){
          _this.deleteShow = true
          return;
        }
        _this.UpdateNumberFn(-1)
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/22 4:21 下午
   * @Description: 修改数量
   * @Params: { number ： num - true } [num: 1-增加 -1-减少]
   * @Return: null
  */
  async UpdateNumberFn(num:number){
    console.log(num);
    const _this:any = this;
    _this.loading = true;
    let params:UpdateNumberReq = {
      cartId:_this.data.cartId,
      cartNumber:num
    }
    const res = await UpdateNumberApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    let message:{action:number,msgContent:any} = {
      action:2002,
      msgContent:{}
    }
    _this.$store.state.webSocket.socketThis.sendFn(message)

    let index = _this.shoppingCartList.findIndex((item:any) => item.cartId == _this.data.cartId)
    _this.shoppingCartList[index] = {
      ...data,
      cartPrice:data.isDeposit==0?data.cartPrice:_this.$base.toFixed(data.cartPrice*data.depositRatio),
      cartPriceOld:data.cartPrice
    }
    _this.shoppingCartList[index].checked = _this.checked
    console.log('------');
    console.log(_this.shoppingCartList);
    console.log('-------------------------A');
    _this.$store.commit('SET_LIST',JSON.stringify(_this.shoppingCartList))

  }


  /**
   * @Author HS
   * @Date 2021/7/22 5:07 下午
   * @Description: 删除产品
   * @Params: null
   * @Return: null
  */
  async DeleteCartFn(){
    const _this:any = this;
    let params:DeleteCartReq = {
      cartId:_this.data.cartId,
    }
    const res = await DeleteCartApi(params)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.$store.commit('REMOVE_LISTITEM',data.cartId)
    _this.deleteShow = false
  }


  /**
   * @Author HS
   * @Date 2021/7/22 5:59 下午
   * @Description: 选择产品
   * @Params: null
   * @Return: null
  */
  checkedItemFn(){
    const _this:any = this;
    // _this.checked = !_this.checked
    let index = _this.shoppingCartList.findIndex((item:any) => item.cartId == _this.data.cartId)
    _this.shoppingCartList[index].checked = !_this.checked
    console.log('-------------------------B');
    _this.$store.commit('SET_LIST',JSON.stringify(_this.shoppingCartList))
    // _this.$emit('checkedCallback',_this.data.cartId,_this.data.cartNum,_this.checked)
  }


  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: null
   * @Return: null
   */
  goDetails(val:any){
    console.log(val);
    const _this:any = this;
    if(_this.data.commoditySeries == 2)return;
    if(_this.data.invalidStatus != 0 || _this.data.commoditySeries == 2 && _this.data.disposalStatus == 3)return;
    let link = ''
    if(_this.data.commoditySeries == 0){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${val.commoditySeries}&i=`+_this.data.commodityId)
    }
    if(_this.data.commoditySeries == 1){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${val.commoditySeries}&i=`+_this.data.commodityId)
    }
    _this.$base.goRouter(link)
  }


}
